import { fetchGet, fetchDelete, fetchPost, fetchGetMess, fetchPostPic} from './index';
import { AttachmentData } from '@/views/Payment/FlRt/types';

//#region 3643
// 获取basic列表
export const basicRuleSearch = (): Promise<AttachmentData[]> => {
    return fetchGet('/claimapi/sampleCheckRule/basicRuleSearch')
}
// 获取special列表
export const specialRuleSearch = (): Promise<AttachmentData[]> => {
    return fetchGet('/claimapi/sampleCheckRule/specialRuleSearch')
}
// 删除basic
export const basicRuleDelete = (params = {}): Promise<AttachmentData[]> => {
    return fetchGet('/claimapi/sampleCheckRule/basicRuleDelete', { params })
}
// 删除special
export const specialRuleDelete = (params = {}): Promise<AttachmentData[]> => {
    return fetchPost('/claimapi/sampleCheckRule/specialRuleDelete', {}, { params })
}
// Special Rule offer type下拉框
export const getSpecialOfferTypeList = (params = {}): Promise<any> => {
    return fetchPost('/claimapi/sampleCheckRule/getProgramBriefInfo', {}, { params })
}
// basic添加校验
export const basicRuleCheck = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/sampleCheckRule/basicRuleCheck', params)
}
// special添加校验
export const specialRuleCheck = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/sampleCheckRule/specialRuleCheck', params)
}
// basic保存
export const basicRulesSave = (params = {}): Promise<AttachmentData[]> => {
    return fetchPost('/claimapi/sampleCheckRule/basicRulesSave', params)
}
// special保存
export const specialRulesSave = (params = {}): Promise<AttachmentData[]> => {
    return fetchPost('/claimapi/sampleCheckRule/specialRulesSave', params)
}
//#endregion

//#region 4644 Bill Rate
export const initBillRateList = (): Promise<any> => {
    return fetchGet('/claimapi/payout/round/payoutRoundYear/initBillRateList')
}
export const getBillRateList = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/payout/round/payoutRoundYear/getBillRateList', { params })
}
export const setBillRate = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/payout/round/payoutRoundYear/setBillRate', params)
}
//#endregion

//#region 4645 Service VAT
export const initServiceVATList = (): Promise<any> => {
    return fetchGet('/claimapi/payout/round/payoutRoundYear/initServiceVATList')
}
export const getServiceVATList = (params = {}): Promise<any> => {
    return fetchGet('/claimapi/payout/round/payoutRoundYear/getServiceVATList', { params })
}
export const setServiceVAT = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/payout/round/payoutRoundYear/setServiceVAT', params)
}
//#endregion

//#region 4646 Floor Plan Days 4647 Contract Activation Days
export const getSettingInfo = (params = {}): Promise<any> => {
    return fetchPost('/claimapi/spotCheck/setting/getSettingInfo', {}, { params })
}
export const setFloorPlanDays = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/spotCheck/setting/floorPlanDays', params)
}
export const setContractActivationDays = (params = {}): Promise<any> => {
    return fetchPostPic('/claimapi/spotCheck/setting/contractActivationDays', params)
}
//#endregion

// findAllEntity
export const getAllEntity = () => {
    return fetchGet('/usapi/entity/entity')
}
// Incentive Type下拉框 获取全部 Offer type
export const getIncentiveTypeDropDownSelectList = (): Promise<AttachmentData[]> => {
    return fetchGet('/pgapi/program/setting/get_offer_type')
}
import { computed, onMounted, reactive, ref, Ref, toRaw } from "vue";
import { getIncentiveTypeDropDownSelectList } from "@/API/spotCheckingConfiguration";
import { getRoundListByDate } from "@/API/payment";
import { getProgramInfoForFl, getProgramInfoForDealer } from "@/API/checking/spotChecking";
import moment from "moment";
const useProgramTracking = () => {
  const column = ref([
    {
      title: "Program Code",
      dataIndex: "programCode",
      width: 150
    },
    {
      title: "Program Name",
      dataIndex: "programName",
      width: 150
    },
    {
      title: "BU",
      dataIndex: "bu",
      width: 50,
    },
    {
      title: "Program Period From",
      dataIndex: "programPeriodFrom",
      width: 180,
    },
    {
      title: "To",
      dataIndex: "to",
      width: 100,
    },
    {
      title: "Offer Type",
      dataIndex: "offerType",
      width: 200,
    },
    {
      title: "Program Close",
      dataIndex: "programClose",
      width: 140,
    },
    {
      title: "Claim No./Amount",
      width: 160,
      slots: {
        customRender: 'Claim'
      }
    },
    {
      title: "SICCO Checked No./Amount",
      width: 220,
      slots: {
        customRender: 'SICCO'
      }
    },
    {
      title: "SIPO Confirm No./Amount",
      width: 220,
      slots: {
        customRender: 'SIPO'
      }
    },
    {
      title: "Spot Checked No./Amount",
      width:220,
      slots: {
        customRender: 'Spot'
      }
    },
    {
      title: "Controlling Checked No./Amount",
      width: 250,
      slots: {
        customRender: 'Controlling'
      }
    },
    {
      title: "Payout Round",
      dataIndex: "payoutRound",
      width: 180,
    },
    {
      title: "Payout Amount",
      dataIndex: "payoutAmount",
      width: 150,
    },
  ]);
  const query = reactive({
    bu: undefined,
    offerType: undefined,
    payoutRound: undefined,
    programClosed: undefined,
    programCode: "",
    programName: "",
    programPeriodEnd: "",
    programPeriodStart: "",
  });
  const pagination = reactive({
    total: 0,
    pageIndex: 1,
    pageSize: 10,
  });
  const period = ref<string[]>([]);
  const initPeriod = () => {
    query.programPeriodStart = moment()
      .month(moment().month())
      .startOf("month")
      .format("YYYY-MM-DD");
    query.programPeriodEnd = moment()
      .month(moment().month())
      .endOf("month")
      .format("YYYY-MM-DD");
    period.value = [query.programPeriodStart, query.programPeriodEnd];
  };
  // const offerTypeRes = ref();
  // const offerTypeList = computed(() => {
  //   return offerTypeRes.value
  //     ? offerTypeRes.value?.incentiveCategory[0].offerType
  //     : [];
  // });
  // const getOfferTypeList = () => {
  //   getIncentiveTypeDropDownSelectList().then((res) => {
  //     offerTypeRes.value = res;
  //   });
  // };
  // offer type list
  const offerTypeRes = ref();
  const offerTypeList = computed(() => {
    if (offerTypeRes.value && offerTypeRes.value.incentiveCategory && Array.isArray(offerTypeRes.value.incentiveCategory)) {
      const arr: any = []
      offerTypeRes.value.incentiveCategory.forEach((item: any) => {
        if (item.offerType && Array.isArray(item.offerType)) {
          arr.push(item.offerType)
        }
      })
      return Array.from(new Set(arr.flat().map((item: any) => item.nameEn)))
    } else {
      return []
    }
  });
  const getOfferTypeList = () => {
    getIncentiveTypeDropDownSelectList().then((res) => {
      offerTypeRes.value = res;
    });
  };
  const roundRes = ref();
  const roundList = computed(() => {
    return roundRes.value ? roundRes.value : [];
  });
  const getRoundList = (type: string) => {
    getRoundListByDate({
      params: {
        month: "0",
        year: "0",
        type,
      },
    }).then((res) => {
      roundRes.value = res;
    });
  };
  const dataRes = ref();
  const data = computed(() => {
    return dataRes.value ? dataRes.value?.content : [];
  });
  const filterQuery = () => {
    const _query = {};
    const obj = toRaw(query);
    for (const i in obj) {
      if (obj[i]) {
        _query[i] = obj[i]
      }
    }
    return _query
  }
  const getFlTableData = () => {
    const _query = {
      ...filterQuery(),
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    }
    getProgramInfoForFl(_query).then((res) => {
      dataRes.value = res;
      pagination.total = res.totalElements
    });
  };
  const getDealerTableData = () => {
    const _query = {
      ...filterQuery(),
      pageIndex: pagination.pageIndex,
      pageSize: pagination.pageSize
    }
    getProgramInfoForDealer(_query).then((res) => {
      dataRes.value = res;
      pagination.total = res.totalElements
    });
  }
  
  return {
    query,
    column,
    data,
    pagination,
    getFlTableData,
    getDealerTableData,
    period,
    initPeriod,
    getOfferTypeList,
    offerTypeList,
    getRoundList,
    roundList,
    filterQuery
  };
};
export default useProgramTracking;

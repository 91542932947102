
import { defineComponent, toRefs, ref } from "vue";
import useProgramTracking from "@/hooks/checking/spotChecking/allClaimsReview/useProgramTracking";
import BuSelector from "@/views/Payment/components/BuSelector.vue";
import TableWrapper from "@/views/Payment/components/TableWrapper.vue";
import downloadFile from "@/utils/claim/downloadFile";
import GetColumnWidth from "@/utils/payment/getColumnWidth";
import moment from 'moment'
export default defineComponent({
  name: "App",
  props: {},
  setup() {
    const {
      query,
      column,
      data,
      getFlTableData,
      getDealerTableData,
      period,
      initPeriod,
      getOfferTypeList,
      offerTypeList,
      getRoundList,
      roundList,
      pagination,
      filterQuery,
    } = useProgramTracking();
    const activeKey = ref('dealer');
    const search = () => {
      if (activeKey.value === "fl") {
        getFlTableData();
      } else {
        getDealerTableData();
      }
    };
    const reset = () => {
      query.bu = undefined;
      query.offerType = undefined;
      query.payoutRound = undefined;
      query.programClosed = undefined;
      query.programCode = "";
      query.programName = "";
      initPeriod();
      search();
    };
    const handlePeriodChange = (period: any) => {
      query.programPeriodStart = moment(period[0]).format("YYYY-MM-DD");
      query.programPeriodEnd = moment(period[1]).format("YYYY-MM-DD");
    };
    const handleExport = () => {
      const params = {
        url: "/claimapi/programTracking/export",
        method: "post",
        params: {
          ...filterQuery(),
          pageIndex: pagination.pageIndex,
          pageSize: pagination.pageSize,
          exportType: activeKey.value === 'dealer' ? 1 : 2
        }
      };
      downloadFile(params, "application/vnd-excel; char-set=UTF-8");
    };
    const map = {
      dealer: 'DEALER',
      fl: 'FINANCE'
    }
    const handleTabChange = (val: string) => {
      getRoundList(map[val])
      search();
    };
    const sizeChange = (page: number, size: number) => {
      pagination.pageIndex = page > 0 ? page : 1;
      pagination.pageSize = size;
      search();
    };
    const pageChange = (page: number, size: number) => {
      pagination.pageIndex = page > 0 ? page : 1;
      pagination.pageSize = size;
      search();
    };
    const init = () => {
      initPeriod();
      getOfferTypeList();
      getRoundList('DEALER');
      search();
    };
    init();
     const tableWidth = ref(GetColumnWidth(column));
    return {
      column,
      data,
      ...toRefs(query),
      ...toRefs(pagination),
      period,
      offerTypeList,
      roundList,
      isPagination: true,
      activeKey,
      tableWidth,
      search,
      reset,
      sizeChange,
      pageChange,
      handleExport,
      handleTabChange,
      handlePeriodChange,
      programCloseList: [
        {
          name: "Closed",
          value: 1,
        },
        {
          name: "Un-Closed",
          value: 0,
        },
      ],
    };
  },
  components: {
    BuSelector,
    TableWrapper,
  },
});

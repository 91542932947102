import { unref } from "vue";
const getColumnWidth = (column: any) => {
  let total = 0;
  unref(column).forEach((item: any) => {
    total += item.width;
  });
  if (total >= document.body.clientWidth - 260) {
    return total;
  } else {
    return null;
  }
};
export default getColumnWidth;

import axios from 'axios';
import { AxiosResponse } from 'axios';
import { fetchGet, fetchDelete, fetchPost, fetchGetMess, fetchPostPic } from '../index';
import { AttachmentData } from '@/views/Payment/FlRt/types';
//#region Payout Preparationl页面接口
// payout detail 列表
export const getPayoutDetailList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/payoutPreparationDetail/searchDealerAndVin', params);
};
export const getPayoutDetailStat = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/payoutPreparationDetail/searchDealerAndVinCount', params);
};
export const getPayoutDetailPageList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/payoutPreparationDetail/searchDealerAndVinPage', params);
};
// payout detail 列表删除
export const deletePayoutDetail = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/payoutPreparationDetail/delDealerAndVin', params);
};
// 4743 获取最新 payout Round
export const getLastPayoutRound = (): Promise<AttachmentData[]> => {
  return fetchGet('/claimapi/payoutPreparation/lastPayoutRound');
};
// 4743 查询列表显示数据
export const getProgramList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/payoutPreparation/listProgram', params);
};
// 4743 add all claim操作
export const addAllClaim = (payoutRoundId: string): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/payoutPreparation/addAllClaim/${payoutRoundId}`);
};
// 4743 submit操作
export const payoutPreparationSubmit = (payoutRoundId: string): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/payoutPreparation/submit/${payoutRoundId}`);
};
// 4743 删除数据 从当前付款轮次剔除
export const payoutPreparationDelete = (params: any): Promise<AttachmentData[]> => {
  return fetchPost(`/claimapi/payoutPreparation/delete`, params);
};
// 4743 获取默认查询条件Year
export const getPayoutPreparationYear = (): Promise<AttachmentData[]> => {
  return fetchGet('/claimapi/payoutPreparation/year');
};
// 4743 弹出Payout Round的list显示
export const getPayoutRoundList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/payoutPreparation/listPayoutRound', params);
};
// 4743 获取payoutRound 前缀
export const getPayoutRoundPrefix = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/payoutPreparation/payoutRoundPrefix', params);
};
// 4743 创建PayoutRound
export const savePayoutRound = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/payoutPreparation/savePayoutRound', params);
};
//#endregion

//#region Checking Preparationl页面接口
// 3760 查询FL列表
export const fetchFlList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/checkingPreparation/finance/list', params);
};
// 3760 查询Dealer列表
export const fetchDealerList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/checkingPreparation/dealer/list', params);
};
// 3760 Set Time Line操作
export const setTimeLine = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/checkingPreparation/setTimeLine', params);
};
// 3761 查看Spot Check的program明细及check状态
export const fetchCheckProgramList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/SpotChecking/packageCode/programList', params);
};
// 3761 comment
export const fetchCheckCommentList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/SpotChecking/packageCode/comment', {}, { params });
};
// 3762 查看Spot Check的金融的program明细及check状态
export const fetchCheckFinanceProgramList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/SpotChecking/payoutCode/programList', params);
};
// 3762 comment
export const fetchCheckFinanceCommentList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/SpotChecking/payoutCode/comment', {}, { params });
};
// 查询checking round列表
export const fetchRoundList = (): Promise<AttachmentData[]> => {
  return fetchGet('/claimapi/checking/round/list');
};
export const checkingroundById = (id: string): Promise<AttachmentData[]> => {
  return fetchGet('/claimapi/checking/plan/export/detail/by/checkingroundid?checkingRoundId=' + id);
};

//#endregion

//#region controlling审批
// 4748 pending页面列表
export const getPendingList = (): Promise<AttachmentData[]> => {
  return fetchGet('/claimapi/pending/approval/list');
};
// 4748 批量审批同意
export const approveList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPostPic('/claimapi/pending/approval/approve', params);
};
// 4748 批量审批拒绝
export const rejectList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/pending/approval/reject', params);
};
// 4752 review页面列表
export const getReviewList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/pendingApprovalAndReview/payoutRoundDetailSearch', params);
};
// 4752 review页面列表分页
export const getReviewPageList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/pendingApprovalAndReview/payoutRoundDetailSearchPage', params);
};
// 4752 上个月（年、月）
export const getDefaultYearAndMonth = (): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/pendingApprovalAndReview/getDefaultYearAndMonth');
};
//#endregion

//#region SPOT_CHECK_CONFIM 4717
// 获取dealer列表
export const getDealerlist = (params = {}): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/dealerList', params);
};
// 获取金融列表
export const getFinancialList = (params = {}): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/financialList', params);
};
// 获取日志列表(dealer)
export const getLoglist = (id: string): Promise<AttachmentData[]> => {
  return fetchGetMess(`/claimapi/spotCheckConfirm/dealer/log/${id}`);
};
//  获取日志列表(financial)
export const getFinancialLoglist = (id: string): Promise<AttachmentData[]> => {
  return fetchGetMess(`/claimapi/spotCheckConfirm/financial/log/${id}`);
};
//获取dealer详情
export const getDealerPackageCodeDetail = (packageId: string): Promise<AttachmentData[]> => {
  return fetchGetMess(`/claimapi/spotCheckConfirm/dealerPackageCodeDetail/${packageId}`);
};
//获取金融详情
export const getFinancialPayoutCodeDetail = (packageId: string): Promise<AttachmentData[]> => {
  return fetchGetMess(`/claimapi/spotCheckConfirm/financialPayoutCodeDetail/${packageId}`);
};
// dealer数据的confirm操作
export const fetchDealerConfirm = (params: any[]): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/dealer/confirm', params);
};
// dealer数据的return操作
export const fetchDealerReturn = (params: any[]): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/dealer/return', params);
};
// dealer列表Confirm to SIPO
export const fetchConfirmtoSIPO = (params: any[]): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/dealer/confirm2SIPO', params);
};
// financial数据的confirm操作
export const fetchFinancialConfirm = (params: any[]): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/financial/confirm', params);
};
// financial数据的return操作
export const fetchFinancialReturn = (params: any[]): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/financial/return', params);
};
// financial数据的confirm to fl操作
export const fetchFinancialtoFl = (payoutCodeId: String): Promise<any> => {
  return fetchGet(`/claimapi/spotCheckConfirm/financial/confirm2FL/${payoutCodeId}`);
};
// log (dealer)
export const fetchDealerLog = (id: string): Promise<any> => {
  return fetchGet(`/claimapi/spotCheckConfirm/dealer/log/${id}`);
};
// return操作预校验
export const dealerVerifyReturn = (packageCodeIds: string[]): Promise<{ data: boolean; message: string }> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/dealer/verify/return', packageCodeIds);
};
export const dealerVerifyReturnByOfferTypeId = (offerTypeId: string): Promise<{ data: boolean; message: string }> => {
  return fetchGetMess(`/claimapi/spotCheckConfirm/dealer/verify/return/${offerTypeId}`, {
    params: {
      offerTypeId
    }
  });
};
export const financialVerifyReturn = (payoutCodeIds: string[]): Promise<{ data: boolean; message: string }> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/financial/verify/return', payoutCodeIds);
};
export const financialVerifyReturnByProgramId = (programId: string): Promise<{ data: boolean; message: string }> => {
  return fetchGetMess(`/claimapi/spotCheckConfirm/financial/verify/return/${programId}`, {
    params: {
      programId
    }
  });
};
export const dealerVerifyConfirm = (packageCodeIds: string[]): Promise<{ data: boolean; message: string }> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/dealer/verify/confirm', packageCodeIds);
};
export const dealerVerifyConfirmByOfferTypeId = (offerTypeId: string): Promise<{ data: boolean; message: string }> => {
  return fetchGetMess(`/claimapi/spotCheckConfirm/dealer/verify/confirm/${offerTypeId}`, {
    params: {
      offerTypeId
    }
  });
};
export const financialVerifyConfirm = (payoutCodeIds: string[]): Promise<{ data: boolean; message: string }> => {
  return fetchPostPic('/claimapi/spotCheckConfirm/financial/verify/confirm', payoutCodeIds);
};
export const financialVerifyConfirmByProgramId = (programId: string): Promise<{ data: boolean; message: string }> => {
  return fetchGetMess(`/claimapi/spotCheckConfirm/financial/verify/confirm/${programId}`, {
    params: {
      programId
    }
  });
};
// 4720 / 4721
// 点击dealer列表packageCode界面Check Status下拉框
export const getProgramDealerStatuslist = (): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/spotCheckConfirm/dealerPackageCodeDetailCheckStatusMap`);
};
// 点击dealer获取program列表
export const getProgramDealerList = (id: string, status: string | number): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/spotCheckConfirm/dealerPackageCodeDetail/${id}/${status}`);
};
// 点击金融获取program列表
export const getProgramFlList = (id: string): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/spotCheckConfirm/financialPayoutCodeDetail/${id}`);
};
// 下载wording file
export const downloadWordingFile = (offerTypeId: string): Promise<AttachmentData[]> => {
  return fetchPost(`/claimapi/spotCheckConfirm/fl/wordingFileDownload?programCodeOfferTypeId=${offerTypeId}`);
};
// overview 点击进入，查询详情及列表得接口
// 获取dealer列表
export const getOverviewDealerlist = (params = {}): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckOverview/dealerList', params);
};
// 获取金融列表
export const getOverviewFinancialList = (params = {}): Promise<any> => {
  return fetchPostPic('/claimapi/spotCheckOverview/financialList', params);
};
// 点击dealer获取program列表
export const getOverviewProgramDealerList = (id: string, status: string | number): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/spotCheckOverview/dealerPackageCodeDetail/${id}/${status}`);
};
// 点击金融获取program列表
export const getOverviewProgramFlList = (id: string): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/spotCheckOverview/financialPayoutCodeDetail/${id}`);
};
//#endregion

//#region Spot Checking Overview
// 4696 Spot Checking Overview进度以及状态列表
export const getSpotCheckingStatusList = (params = {}): Promise<AttachmentData[]> => {
  return fetchPost('/claimapi/SpotChecking/codeList', params);
};
//#endregion

//#region 3821 Pending Checking
// 3821 Pending Checking search dealer list
export const getPendingCheckingDealerList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/pendingChecking/listDealer', params);
};
// 3821 Pending Checking search finance list
export const getPendingCheckingFinanceList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/pendingChecking/listFinance', params);
};
// 3821 Pending Checking 根据packageCodeId查询未confirm的program(dealer)
export const getConfirmProgramList = (packageCodeId: string): Promise<any> => {
  return fetchGet(`/claimapi/pendingChecking/listProgram/${packageCodeId}`);
};
// 3821 Pending Checking confirm program(dealer)
export const confirmProgram = (packageCodeId: string, params: any): Promise<any> => {
  return fetchPostPic(`/claimapi/pendingChecking/confirmProgram/${packageCodeId}`, params);
};
// 3821 Pending Checking confirm payoutRound(金融)
export const confirmPayoutRound = (payoutRoundId: string): Promise<any> => {
  return fetchGetMess(`/claimapi/pendingChecking/confirmPayoutRound/${payoutRoundId}`);
};
// 3821 Pending Checking dealer上传
export const uploadPendingCheckingDealer = (params = {}, formData: FormData): Promise<any> => {
  return fetchPostPic('/claimapi/pendingChecking/upload/uploadDealer', formData, { params });
};
// 3821 Pending Checking WS上传
export const uploadPendingCheckingWs = (params = {}, formData: FormData): Promise<any> => {
  return fetchPostPic('/claimapi/pendingChecking/upload/uploadWS', formData, {
    params
  });
};
// 3821 Pending Checking RT上传
export const uploadPendingCheckingRt = (params = {}, formData: FormData): Promise<any> => {
  return fetchPostPic('/claimapi/pendingChecking/upload/uploadRT', formData, {
    params
  });
};
// 3821 Pending Checking dealer log
export const pendingCheckingDealerLog = (packageCodeId: string): Promise<any> => {
  return fetchPost(`/claimapi/pendingChecking/dealerLog/${packageCodeId}`);
};
// 3821 Pending Checking 金融 log
export const pendingCheckingFinanceLog = (payoutRoundId: string): Promise<any> => {
  return fetchPost(`/claimapi/pendingChecking/financeLog/${payoutRoundId}`);
};
// 3821 多文件下载
export const downloadFiles = (params: any[]): Promise<any> => {
  return fetchPostPic('/claimapi/downloadFiles', params);
};
//#endregion

//#region check confirm -> Controlling checked  4722
export const fetchCheckStatusList = (): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/SpotChecking/checkResultMap`);
};
export const fetchControllingcheckedList = (params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/list`, params);
};
export const fetchControllingFilterByNum = (num: string, params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/filter/new?num=${num}`, params);
};
export const fetchControllingFilterByPercent = (percent: string, params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/filter/new?percent=${percent}`, params);
};
export const fetchControllingFilterByNumAndId = (num: string, params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/filter/new?num=${num}`, params);
};
export const fetchControllingFilterByPercentAndId = (percent: string, params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/filter/new?percent=${percent}`, params);
};
// 获取siOffer列表
export const fetchSiOfferList = (): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/SpotChecking/conditionSi`);
};
// batchConfirmOrReturn , 1 confirm , 2 return
// export const fetchVinDealerBatch = (ids: string, status: string): Promise<any> => {
//     return fetchPost(`/claimapi/SpotChecking/batch?ids=${ids}&status=${status}&isBench=1`)
// }
// export const fetchVinDealer = (ids: string, status: string): Promise<any> => {
//     return fetchPost(`/claimapi/SpotChecking/batch?ids=${ids}&status=${status}&isBench=0`)
// }
export const fetchVinDealerBatch = (params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/batch`, { ...params, isBench: '1' });
};
export const fetchVinDealer = (params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/batch`, { ...params, isBench: '2' });
};
// confirmToSipo,return 1 success,0 fail
export const fetchVinDealerToSipo = (programCode: string, offerType: string, packageCode: string): Promise<any> => {
  return fetchPostPic(
    `/claimapi/SpotChecking/toSipo?programCode=${programCode}&offerType=${offerType}&packageCode=${packageCode}`
  );
};
// 查询车辆明细信息 (dealer)
export const fetchVinDetail = (id: string): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/SpotChecking/vinDetail/${id}`);
};
// 查询车辆明细信息 (金融)
export const fetchVinDetailFl = (vinResultId: string, payoutCode: string): Promise<AttachmentData[]> => {
  return fetchGet(`/claimapi/SpotChecking/vinDetail/finance?vinResultId=${vinResultId}&payoutCode=${payoutCode}`);
};
// vin doc批量下载
export const fetchVinDocDownload = (params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/vin/downloadDocuments`, params);
};
//#endregion
//#region check confirm -> Controlling checked  4723
export const fetchControllingcheckedListFl = (params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/listFl`, params);
};
export const fetchControllingFilterByNumFl = (num: string, params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/filterFl/new?num=${num}`, params);
};
export const fetchControllingFilterByPercentFl = (percent: string, params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/filterFl/new?percent=${percent}`, params);
};
export const fetchControllingFilterByNumAndIdFl = (num: string, params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/filterFl/new?num=${num}`, params);
};
export const fetchControllingFilterByPercentAndIdFl = (percent: string, params: any): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/filterFl/new?percent=${percent}`, params);
};
// batchConfirmOrReturn , 1 confirm , 2 return
export const fetchVinFlBatch = (ids: string[], payoutCodeId: number, status: string, isBench: string): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/batchFl/new`, { ids, payoutCodeId, status, isBench });
};
// confirmToSipo,return 1 success,0 fail
export const fetchVinFlToSipo = (programCode: string, offerType: string, payoutCodeId: string): Promise<any> => {
  return fetchPost(
    `/claimapi/SpotChecking/toSipoFl?programCode=${programCode}&offerType=${offerType}&payoutCodeId=${payoutCodeId}`
  );
};
// 校验 to sipo
export const fetchIsToSipo = (
  programCode: string,
  offerType: string,
  payoutCodeId: string,
  ids: string
): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/isSipo/new`, { ids, offerType, payoutCodeId, programCode });
};
// batch Confirm
export const fetchVinFlBatchConfirm = (offerType: string, programCode: string, payoutCodeId: string): Promise<any> => {
  return fetchPost(
    `/claimapi/SpotChecking/batchFlAll?offerType=${offerType}&programCode=${programCode}&payoutCodeId=${payoutCodeId}&status=1`
  );
};
// batch Return
export const fetchVinFlBatchReturn = (offerType: string, programCode: string, payoutCodeId: string): Promise<any> => {
  return fetchPost(
    `/claimapi/SpotChecking/batchFlAll?offerType=${offerType}&programCode=${programCode}&payoutCodeId=${payoutCodeId}&status=2`
  );
};
// confirm
export const fetchVinFlConfirm = (ids: string[], payoutCodeId: number): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/batchFl/new`, { ids, payoutCodeId, status: 1 });
};
// Return
export const fetchVinFlReturn = (ids: string[], payoutCodeId: number): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/batchFl/new`, { ids, payoutCodeId, status: 2 });
};
//#endregion
//#region 3824 Sample Check
// 3824 Sample Check 查询dealer列表
export const getSampleCheckDealerList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/vendor/listVendorSampleCheckDealerList', params);
};
// 3824 Sample Check 查询金融列表
export const getSampleCheckFinanceList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/vendor/listVendorSampleCheckFLList', params);
};
// 3824 Sample Check dealer抽样
export const getSampleCheckDealerSampling = (params = {}): Promise<any> => {
  return fetchPostPic('/claimapi/vendor/vendorSampleDealerSampling', params);
};
// 3824 Sample Check 金融抽样
export const getSampleCheckFinanceSampling = (params = {}): Promise<any> => {
  return fetchPostPic('/claimapi/vendor/vendorSampleFLSampling', params);
};
//#endregion

//#region 3825 Controlling Vendor_Sample Checking Detail Page
// dealer 列表
export const getSampleCheckDetailDealerList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/SpotChecking/Checking/SampleCheck', params);
};
// fl 列表
export const getSampleCheckDetailFlList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/SpotChecking/Checking/FL/SampleCheck', params);
};
// Batch Confirm Sample Check Result(Delaer)
export const SampleCheckConfirm = (params: any[], id: string): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/Checking/SampleCheckConfirm?packageCodeId=${id}`, params);
};
// Batch Confirm Sample Check Result TotalAmount(Dealer)
export const SampleCheckConfirmResult = (params: any[], id: string): Promise<any> => {
  return fetchPostPic(`/claimapi/SpotChecking/Checking/SampleCheckConfirmResult?packageCodeId=${id}`, params);
};
// Batch Confirm Sample Check Result(FL)
export const SampleCheckPayoutRoundConfirm = (payoutRoundId: string, type: string): Promise<any> => {
  return fetchPost(
    `/claimapi/SpotChecking/Checking/SampleCheckPayoutRoundConfirm?payoutRoundId=${payoutRoundId}&type=${type}`
  );
};
// Batch Confirm Sample Check Result TotalAmount(FL)
export const SampleCheckPayoutRoundConfirmResult = (payoutRoundId: string, type: string): Promise<any> => {
  return fetchPostPic(
    `/claimapi/SpotChecking/Checking/SampleCheckPayoutRoundConfirmResult?payoutRoundId=${payoutRoundId}&type=${type}`
  );
};
// 上传dealer
export const uploadSampleCheckResult = (params = {}, formData: FormData): Promise<any> => {
  return fetchPostPic('/claimapi/SpotChecking/Checking/uploadSampleCheckResult', formData, { params });
};
// 上传fl
export const uploadSampleCheckResultFl = (params = {}, formData: FormData): Promise<any> => {
  return fetchPostPic('/claimapi/SpotChecking/Checking/uploadSampleCheckResultFl', formData, { params });
};
// 导出
export const exportSupportDoc = (packageCodeId: string): Promise<any> => {
  return fetchPost(`/claimapi/SpotChecking/Checking/export/support/doc?packageCodeId=${packageCodeId}`);
};
// 下载
// export const downloadSupportDoc = (packageCodeId: string, programCode: string): Promise<any> => {
//     return fetchPost(`/claimapi/SpotChecking/Checking/download/support/doc?packageCodeId=${packageCodeId}&programCode=${programCode}`)
// }
// 3825 下载替换接口
export const downloadSupportDoc = (params = {}): Promise<any> => {
  return fetchPostPic(`/claimapi/SpotChecking/Checking/download/support/doc`, params);
};
//#endregion
//#region 4734 Controlling_All Claim Review_Package Tracking
export const getPackageTrackingDealerList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/packageTracking/dealerList', params);
};
export const getPackageTrackingFlList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/packageTracking/financialList', params);
};
//#endregion

//#region 4370 Checked
// 4370 Checked 查询dealer列表
export const getCheckedDealerList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/vendor/checked/dealer/list', params);
};
// 4370 Checked 查询金融列表
export const getCheckedFinanceList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/vendor/checked/finance/list', params);
};
// 4370 Checked 上传文件
export const uploadChecked = (params = {}, formData: FormData): Promise<any> => {
  return fetchPostPic('/claimapi/vendor/checked/upload/report', formData, {
    params
  });
};
// 4370 Checked 文件列表
export const getCheckedFileList = (params = {}): Promise<any> => {
  return fetchGet('/claimapi/vendor/checked/listFile', { params });
};
// 4370 Checked 删除文件
export const getCheckedDeleteFile = (params = {}): Promise<any> => {
  return fetchGetMess('/claimapi/vendor/checked/deleteFile', { params });
};
// 4370 Checked 下载文件
export const getCheckedDownloadFile = (fileId: string): Promise<any> => {
  return fetchGetMess(`/claimapi/vendor/checked/downloadUrl/${fileId}`);
};
// 4370 点击dealer packageCode展示列表programCode + offerType
export const getPackageCodeInfo = (id: string): Promise<any> => {
  return fetchGet(`/claimapi/vendor/checked/getPackageCodeInfo/${id}`);
};
export const getProgramOfferTypeList = (id: string, status: string): Promise<any> => {
  return fetchGet(`/claimapi/vendor/checked/programOfferTypeList/${id}/${status}`);
};
//#endregion

//#region 4736 All Claims Review-Claim Checking
export const getClaimCheckingDealerList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/claimTracking/listClaimInfoDealer', params);
};
export const getClaimCheckingWSList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/claimTracking/listClaimInfoFlWs', params);
};
export const getClaimCheckingRTList = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/claimTracking/listClaimInfoFl', params);
};
export const claimTrackingDealerExport = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/claimTracking/dealerExport', params);
};
export const claimTrackingFlRtexport = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/claimTracking/flRtexport', params);
};
export const claimTrackingFlWsexport = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/claimTracking/flWsexport', params);
};
//#endregion

//#region 4735 Controlling_All Claim Review_Program Tracking
export const getProgramInfoForFl = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/programTracking/listProgramInfoForFl', params);
};
export const getProgramInfoForDealer = (params = {}): Promise<any> => {
  return fetchPost('/claimapi/programTracking/listProgramInfo', params);
};
//#endregion

export const dealerAddComments1 = (comments: string, ids: string[]): Promise<any> => {
  return fetchPost('/claimapi/spotCheckConfirm/dealer/verify/return/addComments1', ids, {
    params: {
      comments
    }
  });
};
export const dealerAddComments2 = (comments: string, ids: string[]): Promise<any> => {
  return fetchPost('/claimapi/spotCheckConfirm/dealer/verify/return/addComments2', ids, {
    params: {
      comments
    }
  });
};
export const getCheckBeforeConfirm = (params = { ids: [] as any }): Promise<any> => {
  return fetchPost('/claimapi/spotCheckConfirm/dealer/verify/confirm/checkBeforeConfirm', [...params.ids]);
};
export const verifyFinancialCheckBeforeConfirm = (payoutCodeIds: string[]): Promise<boolean> => {
  return fetchPost('/claimapi/spotCheckConfirm/financial/verify/confirm/checkBeforeConfirm', payoutCodeIds);
};
export const financialAddComments1 = (comments: string, payoutCodeIds: string[]): Promise<any> => {
  return fetchPost('/claimapi/spotCheckConfirm/financial/verify/return/addComments1', {
    comments,
    payoutCodeIds
  });
};
export const financialAddComments2 = (comments: string, payoutCodeIds: string[]): Promise<any> => {
  return fetchPost('/claimapi/spotCheckConfirm/financial/verify/return/addComments2', {
    comments,
    payoutCodeIds
  });
};
// 17451 Demo car 导出
export const isExportDemoCar = (packageCodeId: string): Promise<boolean> => {
  return fetchGet('/claimapi/SpotChecking/Checking/isExportDemoCar', {
    params: {
      packageCodeId
    }
  });
};
export const exportDemoCar = (packageCodeId: string): Promise<AxiosResponse<Blob>> => {
  return axios.get('/claimapi/SpotChecking/Checking/exportDemoCar', {
    responseType: 'blob',
    params: {
      packageCodeId
    }
  });
};
// 3822 Download source data
export const downloadSourceData = (packageCodeId: string): Promise<any> => {
  return axios.get(`/claimapi/pendingChecking/download/downloadSource/${packageCodeId}`, {
    // responseType: "blob",
    params: {
      packageCodeId
    }
  });
};

// 17097 Controlling confirm to F&L
export const financialConfirm2FLTip = (offerType: string, programCode: string, payoutCodeId: string): Promise<any> => {
  return fetchPost(
    '/claimapi/SpotChecking/financial/confirm2FLTip',
    {},
    {
      params: {
        offerType,
        programCode,
        payoutCodeId
      }
    }
  );
};

export const getBatchConfirmResultList = (packageCodeId: any) => {
  return fetchGet(`/claimapi/spotCheckConfirm/dealer/batchConfirmResultList/${packageCodeId}`);
};

export const confirmBatch = (params: any) => {
  return fetchPost(`/claimapi/spotCheckConfirm/dealer/confirm/batch`, params);
};

export const getBatchConfirmResultListControllingReviewed = (params: any) => {
  return fetchPost(`/claimapi/SpotChecking/dealer/batchConfirmResultList`, params);
};

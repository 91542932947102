
import { defineComponent, PropType } from "vue";
import { useStore } from "vuex";
import useModel from "@/hooks/useModel";

export default defineComponent({
  props: {
    value: {
      type: [String, Array] as PropType<any | any[]>,
    },
  },
  setup(props) {
    const _value = useModel(props, "value");
    const mockData = [
      { nameEn: "MB" },
      { nameEn: "MB East" },
      { nameEn: "MB West" },
      { nameEn: "MB North" },
      { nameEn: "MB South" },
      { nameEn: "MB G Class" },
      { nameEn: "EQ" },
      { nameEn: "AMG" },
      { nameEn: "CPO" },
      { nameEn: "MB CPO" },
      { nameEn: "AMG CPO" },
      { nameEn: "Company car" },
      { nameEn: "VAN" },
      { nameEn: "VAN Fleet" },
      { nameEn: "Fleet" },
      { nameEn: "DENZA" },
      { nameEn: "DENZA Fleet" },
    ];
    const { state } = useStore();
    // const buList = mockData;
    const buList = state.user.buList;
    const reset = () => {
      if (typeof props.value === 'object') {
        _value.value = [];
      } else {
        _value.value = "";
      }
    };
    return {
      buList,
      _value,
      reset
    };
  },
});
